<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="card mb-4">
                <h1>Rate your Strengths</h1>
                <p>Enter your top 5 Gallup Strengths™ below to receive your canonical, objective rating.</p>
                <p>Then, visit the <a href="/leaderboard">leaderboard</a> to see how you rank against others!</p>
            </div>
            <app-strengths-input
                (resultChange)="onResultChange($event)">
            </app-strengths-input>
        </div>
        <div class="col-md-6">
            <app-entry-results
                [result]="result"
                *ngIf="result != null">
            </app-entry-results>
        </div>
    </div>
</div>
