export enum Strength {
    Achiever = "Achiever",
    Activator = "Activator",
    Adaptability = "Adaptability",
    Analytical = "Analytical",
    Arranger = "Arranger",
    Belief = "Belief",
    Command = "Command",
    Communication = "Communication",
    Competition = "Competition",
    Connectedness = "Connectedness",
    Consistency = "Consistency",
    Context = "Context",
    Deliberative = "Deliberative",
    Developer = "Developer",
    Discipline = "Discipline",
    Empathy = "Empathy",
    Focus = "Focus",
    Futuristic = "Futuristic",
    Harmony = "Harmony",
    Ideation = "Ideation",
    Includer = "Includer",
    Individualization = "Individualization",
    Input = "Input",
    Intellection = "Intellection",
    Learner = "Learner",
    Maximizer = "Maximizer",
    Positivity = "Positivity",
    Relator = "Relator",
    Responsibility = "Responsibility",
    Restorative = "Restorative",
    SelfAssurance = "Self-Assurance",
    Significance = "Significance",
    Strategic = "Strategic",
    Woo = "Woo",
}

export const STRENGTHS = [
    Strength.Achiever,
    Strength.Activator,
    Strength.Adaptability,
    Strength.Analytical,
    Strength.Arranger,
    Strength.Belief,
    Strength.Command,
    Strength.Communication,
    Strength.Competition,
    Strength.Connectedness,
    Strength.Consistency,
    Strength.Context,
    Strength.Deliberative,
    Strength.Developer,
    Strength.Discipline,
    Strength.Empathy,
    Strength.Focus,
    Strength.Futuristic,
    Strength.Harmony,
    Strength.Ideation,
    Strength.Includer,
    Strength.Individualization,
    Strength.Input,
    Strength.Intellection,
    Strength.Learner,
    Strength.Maximizer,
    Strength.Positivity,
    Strength.Relator,
    Strength.Responsibility,
    Strength.Restorative,
    Strength.SelfAssurance,
    Strength.Significance,
    Strength.Strategic,
    Strength.Woo,
];