import { Component, OnInit } from '@angular/core';
import { StrengthsGetResponse, StrengthsService } from '../strengths.service';

export type LeaderboardEntry = {
  name: string,
  score: number,
};

export type TierEntry = {
  tierName: string,
  tierClass: string,
  entries: LeaderboardEntry[]
};

function tierEntryWithName(tierName:string):TierEntry {
  return {
    tierName: tierName,
    tierClass: tierName.toLowerCase().replace("+", "plus"),
    entries: []
  };
}

const TIER_NAMES = ["S+", "S", "A" ,"B", "C", "D", "F"];

@Component({
  selector: 'app-leaderboard-page',
  templateUrl: './leaderboard-page.component.html',
  styleUrls: ['./leaderboard-page.component.css']
})
export class LeaderboardPageComponent implements OnInit {

  tiers:TierEntry[] = TIER_NAMES.map(tierEntryWithName);

  constructor(private strengthsService:StrengthsService) {
    strengthsService.getStrengths().subscribe(response => {
      this.tiers = this.calc_tiers(response);
    });
  }

  calc_tiers(response:StrengthsGetResponse):TierEntry[] {
    if (response.length == 0) {
      return TIER_NAMES.map(tierEntryWithName);
    }
    let tierIndex = 0;
    let tiers:TierEntry[] = [];
    while (response[0].tier != TIER_NAMES[tierIndex]) {
      tiers.push(tierEntryWithName(TIER_NAMES[tierIndex]));
      tierIndex++;
    }
    let currentTier:TierEntry = tierEntryWithName(response[0].tier);
    for (let entry of response) {
      if (entry.tier != currentTier.tierName) {
        tiers.push(currentTier);
        currentTier = tierEntryWithName(entry.tier);
        tierIndex++;
      }
      currentTier.entries.push({
        name: entry.name,
        score: entry.score,
      });
    }
    tiers.push(currentTier);
    for (tierIndex = tierIndex + 1; tierIndex < TIER_NAMES.length; tierIndex++) {
      tiers.push(tierEntryWithName(TIER_NAMES[tierIndex]));
    }
    return tiers;
  }

  ngOnInit(): void {
  }

}
