import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export type StrengthsGetResponse = {
    name: string,
    score: number,
    tier: string,
}[];

export type StrengthsPostViewModel = {
  name: string,
  strength0: string,
  strength1: string,
  strength2: string,
  strength3: string,
  strength4: string,
};
export type StrengthsPostResponse = {
  score:number,
  text:string
};

@Injectable({
  providedIn: 'root'
})
export class StrengthsService {

  private static API_BASE = environment.production ? "/" : "http://localhost:6660/";

  constructor(private http:HttpClient) { }

  getStrengths():Observable<StrengthsGetResponse> {
    const url = StrengthsService.API_BASE + "strengths";
    return this.http.get<StrengthsGetResponse>(url);
  }

  postStrenths(viewModel:StrengthsPostViewModel):Observable<StrengthsPostResponse> {
    const url = StrengthsService.API_BASE + "strengths";
    const body = JSON.stringify(viewModel);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    let options = { headers: headers };
    return this.http.post<StrengthsPostResponse>(url, body, options);
  }
}
