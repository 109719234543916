import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StrengthsInputComponent } from './strengths-input/strengths-input.component';
import { StrengthSelectComponent } from './strength-select/strength-select.component';
import { EntryPageComponent } from './entry-page/entry-page.component';
import { EntryResultsComponent } from './entry-results/entry-results.component';
import { LeaderboardPageComponent } from './leaderboard-page/leaderboard-page.component';
import { TierSectionComponent } from './tier-section/tier-section.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SettingsPageComponent } from './settings-page/settings-page.component';

@NgModule({
  declarations: [
    AppComponent,
    StrengthsInputComponent,
    StrengthSelectComponent,
    EntryPageComponent,
    EntryResultsComponent,
    LeaderboardPageComponent,
    TierSectionComponent,
    SettingsPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    Title,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
