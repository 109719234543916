import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Strength } from '../strengths';
import { StrengthsService, StrengthsPostResponse } from '../strengths.service';


@Component({
  selector: 'app-strengths-input',
  templateUrl: './strengths-input.component.html',
  styleUrls: ['./strengths-input.component.css']
})
export class StrengthsInputComponent implements OnInit {

  name = "";
  strengths:Strength[] = [Strength.Achiever, Strength.Activator, Strength.Adaptability, Strength.Analytical, Strength.Arranger];

  @Output()
  resultChange = new EventEmitter<StrengthsPostResponse>();

  constructor(private strengthsService:StrengthsService) { }

  ngOnInit(): void {
  }

  usedStrengthsAt(index:number):Strength[] {
    return this.strengths.slice(0, index);
  }

  onStrengthAtIndexChange(index:number, strength:Strength) {
    // Duplicate strengths are invalid. It's much quicker to swap
    // strengths by changing duplicates than by changing to a wrong
    // strength, changing the other one to the previous strength,
    // then changing back: 1 step vs 3.
    for (let i = 0; i < this.strengths.length; i++) {
      if (i == index) {
        continue;
      }
      if (this.strengths[i] == strength) {
        this.strengths[i] = this.strengths[index];
        // Strengths start without duplicates, so there can
        // only be one. No need to continue searching.
        break;
      }
    }
    this.strengths[index] = strength;
    this.strengths = this.strengths.map((s) => { return s; });
  }

  onSubmit(event:Event) {
    event.preventDefault();
    const form = <HTMLFormElement>event.target;
    const elements:any = form.elements;
    const viewModel = {
      name: elements["name"].value,
      strength0: elements["strength0"].value,
      strength1: elements["strength1"].value,
      strength2: elements["strength2"].value,
      strength3: elements["strength3"].value,
      strength4: elements["strength4"].value,
    };
    this.strengthsService.postStrenths(viewModel).subscribe((response) => {
      this.resultChange.emit(response);
    });
    return false;
  }

}
