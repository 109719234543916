import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EntryPageComponent } from './entry-page/entry-page.component';
import { LeaderboardPageComponent } from './leaderboard-page/leaderboard-page.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';

const routes: Routes = [
  { path: 'leaderboard', component: LeaderboardPageComponent },
  { path: 'home', component: EntryPageComponent },
  { path: 'settings', component: SettingsPageComponent },
  { path: '**', component: EntryPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
