<form name="strengths-input-form" id="strengths-input-form" class="card mb-4" (submit)="onSubmit($event)">
    <div class="form-group">
        <label for="name" class="control-label">Name</label>
        <input name="name" id="name" class="form-control" [(ngModel)]="name"/>
    </div>
    <!-- There's a nasty Angular bug that changes multiple selects if they
         have the same value. Manually unrolling the loop fixes this. -->
    <app-strength-select
        [index]=0
        [strength]="strengths[0]"
        (strengthChange)="onStrengthAtIndexChange(0, $event)">
    </app-strength-select>
    <app-strength-select
        [index]=1
        [strength]="strengths[1]"
        (strengthChange)="onStrengthAtIndexChange(1, $event)">
    </app-strength-select>
    <app-strength-select
        [index]=2
        [strength]="strengths[2]"
        (strengthChange)="onStrengthAtIndexChange(2, $event)">
    </app-strength-select>
    <app-strength-select
        [index]=3
        [strength]="strengths[3]"
        (strengthChange)="onStrengthAtIndexChange(3, $event)">
    </app-strength-select>
    <app-strength-select
        [index]=4
        [strength]="strengths[4]"
        (strengthChange)="onStrengthAtIndexChange(4, $event)">
    </app-strength-select>
    <button type="submit" class="btn btn-primary">Submit</button>
</form>