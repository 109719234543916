import { Component, OnInit } from '@angular/core';
import { DARK_THEME, LIGHT_THEME, StorageService } from '../storage.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.css']
})
export class SettingsPageComponent implements OnInit {

  theme = "light";

  constructor(private storageService:StorageService) { }

  ngOnInit(): void {
    this.theme = this.storageService.getTheme();
  }

  private setLightMode() {
    this.storageService.setTheme(LIGHT_THEME);
  }

  private setDarkMode() {
    this.storageService.setTheme(DARK_THEME);
  }
  
  lightThemeChanged(x:any) {
    this.setLightMode();
  }

  darkThemeChanged(x:any) {
    this.setDarkMode();
  }
}
