import { Injectable } from '@angular/core';

const ON = "initial";
const OFF = " ";
const THEME_KEY = "theme";
export const LIGHT_THEME = "light";
export const DARK_THEME = "dark";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
    let savedTheme = localStorage.getItem(THEME_KEY);
    if (savedTheme != null) {
      this.setTheme(savedTheme);
    }
  }

  getTheme():string {
    let value = document.documentElement.style.getPropertyValue("--light");
    if (value == "initial") {
      return LIGHT_THEME;
    } else {
      return DARK_THEME;
    }
  }

  private setCustomProperties(theme:string):boolean {
    switch (theme) {
      case LIGHT_THEME:
        document.documentElement.style.setProperty("--light", ON);
        document.documentElement.style.setProperty("--dark", OFF);
        return true;
      case DARK_THEME:
        document.documentElement.style.setProperty("--light", OFF);
        document.documentElement.style.setProperty("--dark", ON);
        return true;
      default:
        return false;
    }
  }

  setTheme(theme:string) {
    let validTheme = this.setCustomProperties(theme);
    if (!validTheme) {
      return;
    }
    localStorage.setItem(THEME_KEY, theme);
  }

}
