<nav class="navbar navbar-expand-md navbar-light bg-primary mb-4">
  <img class="brand-image" src="/assets/icon.jpg" aria-label="">
  <button class="navbar-toggler {{navbarTogglerClass}}" type="button" data-toggle="collapse" data-target="#navbar-content" aria-controls="navbarSupportedContent" [attr.aria-expanded]="isNavbarExpanded()" aria-label="Toggle navigation" (click)="toggleMenuPressed($event)">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse {{navbarContentClass}}" id="navbar-content">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item"><a class="nav-link" routerLink="/home" routerLinkActive="active">Home</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/leaderboard" routerLinkActive="active">Leaderboard</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a></li>
    </ul>
  </div>
</nav>
<router-outlet></router-outlet>