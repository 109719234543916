import { Component, OnInit } from '@angular/core';
import { StrengthsPostResponse } from '../strengths.service';

@Component({
  selector: 'app-entry-page',
  templateUrl: './entry-page.component.html',
  styleUrls: ['./entry-page.component.css']
})
export class EntryPageComponent implements OnInit {

  result:StrengthsPostResponse|null = null;

  constructor() { }

  ngOnInit(): void {
  }

  onResultChange(result:StrengthsPostResponse) {
    this.result = result;
  }

}
