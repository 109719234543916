import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Strength, STRENGTHS } from '../strengths';

@Component({
  selector: 'app-strength-select',
  templateUrl: './strength-select.component.html',
  styleUrls: ['./strength-select.component.css']
})
export class StrengthSelectComponent implements OnInit {

  @Input()
  index:number = 0;

  @Input()
  strength = Strength.Achiever;

  @Output()
  strengthChange = new EventEmitter<Strength>();

  constructor() { }

  ngOnInit(): void {
  }

  strengths():Strength[] {
    return STRENGTHS;
  }

  onStrengthChanged(strength:Strength) {
    this.strength = strength;
    this.strengthChange.next(strength);
  }

}
