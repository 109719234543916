<div class="container">
    <div class="row">
        <div class="col-12">
            <div *ngFor="let tier of tiers" class="{{tier.tierClass}} tier">
                <h2>Tier {{tier.tierName}}</h2>
                <table *ngIf="tier.entries.length > 0; else emptyTier">
                    <thead class="">
                        <tr>
                            <th>Name</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let entry of tier.entries">
                            <td>{{entry.name}}</td>
                            <td>{{entry.score}}</td>
                        </tr>
                    </tbody>
                </table>
                <ng-template #emptyTier>
                    <p>No entries.</p>
                </ng-template>
            </div>
        </div>
    </div>
</div>
