import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StorageService } from './storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ui';

  navbarTogglerClass = "collapsed";
  navbarContentClass = "";

  // Inject a storage service so the theme is loaded from local storage
  // on page load, if one exists.
  constructor(private storageService:StorageService, private titleService:Title) {
    titleService.setTitle("Strengths Rater");

    // Building angular in production configuration runs a CSS cleaner
    // that strips out the empty property. Since it's not in the style
    // sheet, the initial load breaks. Manually set it to fix the initial
    // load.
    document.documentElement.style.setProperty("--OFF", " ");
  }

  isNavbarExpanded():boolean {
    return this.navbarTogglerClass == "";
  }

  toggleMenuPressed(event:Event) {
    if (this.isNavbarExpanded()) {
      this.navbarTogglerClass = "collapsed";
      this.navbarContentClass = "";
    } else {
      this.navbarTogglerClass = "";
      this.navbarContentClass = "show";
    }
  }
}
