<div class="container">
    <div class="row card">
        <div class="col-md-12">
            <h1>Settings</h1>
            <h2>Theme</h2>
            <p>Manually switch your color theme here.</p>
            <div class="form-check">
                <input type="radio" name="theme" id="light" class="form-check-input" value="light" [(ngModel)]="theme" (ngModelChange)="lightThemeChanged($event)"/>
                <label for="light" class="form-check-label">Light</label>
            </div>
            <div class="form-check">
                <input type="radio" name="theme" id="dark" class="form-check-input" value="dark" [(ngModel)]="theme" (ngModelChange)="darkThemeChanged($event)"/>
                <label for="dark" class="form-check-label">Dark</label>
            </div>
        </div>
    </div>
</div>