import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tier-section',
  templateUrl: './tier-section.component.html',
  styleUrls: ['./tier-section.component.css']
})
export class TierSectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
