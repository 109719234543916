import { Component, Input, OnInit } from '@angular/core';
import { StrengthsPostResponse } from '../strengths.service';

@Component({
  selector: 'app-entry-results',
  templateUrl: './entry-results.component.html',
  styleUrls: ['./entry-results.component.css']
})
export class EntryResultsComponent implements OnInit {

  @Input()
  result!:StrengthsPostResponse;

  constructor() { }

  ngOnInit(): void {
  }

}
